export default {
  versions: `/v1/units/versions`,
  version: (id: string) => `/v1/units/versions/${id}`,
  relation: (id: string) => `/v1/units/relations/${id}`,
  test_answer_results: `/v1/units/test_answer_results`,
  event_results: `/v1/units/event_results`,
  event_result: (id: string) => `/v1/units/event_results/${id}`,
  event_version: (id: string) => `/v1/units/event_versions/${id}`,
  confirm: `/v1/units/confirm`,
  reschedule: `/v1/units/reschedule`,
  test_question_versions: (id: string) =>
    `/v1/units/test_question_versions/${id}`,
  document_download: `/v1/units/documents`
};
